<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="error"-->
<!--                effect="dark" style="margin-bottom: 20px" >-->
<!--            </el-alert>-->
          </div>



          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[16].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[16].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[17].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[17].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[18].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[18].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[19].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[19].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "DongJi",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://image.yabyy.com/home/dongji/1000539.png','id':'4458','search':''},      //4458	1000539	医用热敷贴
        {'index':1,'url':'http://image.yabyy.com/home/dongji/1008469.png','id':'10138','search':''},     //10138	1008469	暖宝宝(暖手)
        {'index':2,'url':'http://image.yabyy.com/home/dongji/1009903.png','id':'10953','search':''},     //10953	1009903	KN95防护口罩(柳叶型)
        {'index':3,'url':'http://image.yabyy.com/home/dongji/1015293.png','id':'13753','search':''},     //13753	1015293	一次性使用医用口罩(耳挂式 儿童型)(淮海)

        {'index':4,'url':'http://image.yabyy.com/home/dongji/1003347.png','id':'6601','search':''},      //6601	1003347	曼秀雷敦薄荷润唇膏SPF15
        {'index':5,'url':'http://image.yabyy.com/home/dongji/1003348.png','id':'18225','search':''},     //18225	1003348	曼秀雷敦天然润唇膏@
        {'index':6,'url':'http://image.yabyy.com/home/dongji/1003351.png','id':'6604','search':''},      //6604	1003351	曼秀雷敦特柔润唇膏SPF15
        {'index':7,'url':'http://image.yabyy.com/home/dongji/1006918.png','id':'9162','search':''},      //9162	1006918	什果冰小小润唇膏-草莓

        {'index':8,'url':'http://image.yabyy.com/home/dongji/1007202.png','id':'9356','search':''},      // 9356	1007202	尿素维E乳膏
        {'index':9,'url':'http://image.yabyy.com/home/dongji/1002403.png','id':'5854','search':''},      //5854	1002403	氢溴酸右美沙芬糖浆
        {'index':10,'url':'http://image.yabyy.com/home/dongji/1012624.png','id':'12404','search':''},    //12404	1012624	暖宝宝暖贴
        {'index':11,'url':'http://image.yabyy.com/home/dongji/1008476.png','id':'10142','search':''},    //9440	1007334	钢管手动轮椅车

        {'index':12,'url':'http://image.yabyy.com/home/dongji/1003267.png','id':'18223','search':''},    //18223	1003267	曼秀雷敦天然植物润唇膏-无香料
        {'index':13,'url':'http://image.yabyy.com/home/dongji/1006921.png','id':'9164','search':''},     //9164	1006921	曼秀雷敦男士润唇膏－户外型
        {'index':13,'url':'http://image.yabyy.com/home/dongji/1008468.png','id':'10137','search':''},    //10137	1008468	暖宝宝(生理期用)
        {'index':15,'url':'http://image.yabyy.com/home/dongji/1008861.png','id':'10335','search':''},    //10335	1008861	暖宝宝(肩颈专用)

        {'index':16,'url':'http://image.yabyy.com/home/dongji/1007090.png','id':'9273','search':''},     //9273	1007090	曼秀雷敦天然植物润唇膏(香橙)
        {'index':17,'url':'http://image.yabyy.com/home/dongji/1007159.png','id':'9323','search':''},     //9323	1007159	曼秀雷敦男士润唇膏-天然型
        {'index':18,'url':'http://image.yabyy.com/home/dongji/1008367.png','id':'10075','search':''},    //10075	1008367	曼秀雷敦 天然植物精华油润唇膏-无香料
        {'index':19,'url':'http://image.yabyy.com/home/dongji/1008368.png','id':'10076','search':''},    //10076	1008368	曼秀雷敦 天然植物精华油润唇膏-蔷薇果

      ],
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
